import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
    Koti: "/",
    "Tietosuojakäytäntö": "fi/tietosuojakäytäntö"
  };
  
// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/privacy-policy"
);

  const PrivacyPolicy = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO
          title="Tietosuojakäytäntö"
          description="Tietoa tietosuojakäytännöstäni, jonka tarkoituksena on selittää, kuinka palveluni toimivat ja kuinka henkilötietojesi suojaus taataan."
          lang="fi"
          alternateLangs={alternateLangs}
          meta={[{ name: "robots", content: "noindex" }]}
        />
        <MainContent article>
          <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

          <H as="h1">Tietosuoja</H>
          <p>Tietosuojakäytäntö</p>
        <p>
          Alla haluaisin selittää, mitä tietoja kerään, käsittelen ja käytän, milloin ja mihin tarkoitukseen. Tarkoituksena on
          selittää, miten tarjoamani palvelut toimivat ja miten henkilötietojesi suojaus taataan tässä yhteydessä.
        </p>
        <p>Kerään, käsittelen ja käytän henkilötietoja vain, jos olet antanut suostumuksesi tai laki sallii sen.</p>
        <p>
          Tämä tietosuojakäytäntö voidaan ladata, tallentaa ja tulostaa milloin tahansa osoitteesta
          https://matthiaskupperschmidt.com/privacy-policy/.
        </p>
        <H as="h2">Rekisterinpitäjän nimi ja osoite</H>
        <p>Rekisterinpitäjä tietosuojalainsäädännön mukaisesti on:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Kööpenhamina, Tanska</p>
        <p>Voit ottaa minuun yhteyttä milloin tahansa sähköpostitse osoitteessa legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Tietosuojavastaavan nimi ja osoite</H>
        <p>Tietosuojavastaavan yhteystiedot:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 Kööpenhamina, Tanska</p>
        <p>Voit ottaa minuun yhteyttä milloin tahansa sähköpostitse osoitteessa legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Yleistä tietosuojasta</H>
        <p>Henkilötietojen käsittelyn laajuus</p>
        <p>
          Käsittelen käyttäjien henkilötietoja vain silloin, kun se on tarpeen toimivan verkkosivuston sekä sisällön
          ja palveluiden tarjoamiseksi. Yleensä käyttäjien henkilötietoja käsitellään vain, kun käyttäjä on antanut suostumuksensa. Poikkeus
          koskee tapauksia, joissa suostumusta ei voida saada etukäteen tosiasiallisista syistä ja tietojen käsittely on sallittua
          lakisääteisten säännösten nojalla.
        </p>
        <H as="h2">Henkilötietojen käsittelyn oikeusperusta</H>
        <p>
          EU:n yleisen tietosuoja-asetuksen (GDPR) 6 artiklan 1 kohdan a alakohta toimii oikeusperustana, kun saan
          rekisteröidyn suostumuksen henkilötietojen käsittelyyn.
        </p>
        <p>
          GDPR:n 6 artiklan 1 kohdan b alakohta toimii oikeusperustana, kun henkilötietojen käsittely on tarpeen
          sopimuksen täyttämiseksi, jossa rekisteröity on osapuolena. Tämä koskee myös käsittelyä,
          joka on tarpeen ennen sopimusta toteutettavien toimenpiteiden täytäntöön panemiseksi.
        </p>
        <p>
          GDPR:n 6 artiklan 1 kohdan c alakohta toimii oikeusperustana, kun henkilötietojen käsittely on tarpeen
          oikeudellisen velvoitteen täyttämiseksi, johon yritykseni on velvoitettu.
        </p>
        <p>
          Kun käsittely on tarpeen yritykseni tai kolmannen osapuolen oikeutetun edun turvaamiseksi, ja
          rekisteröidyn edut ja perusoikeudet ja -vapaudet eivät syrjäytä ensiksi mainittua
          etua, GDPR:n 6 artiklan 1 kohdan f alakohta toimii oikeusperustana käsittelylle.
        </p>

        <H as="h2">Tietojen poistaminen ja säilytysaika</H>
        <p>
          Rekisteröidyn henkilötiedot poistetaan tai estetään heti, kun säilytystarkoitus lakkaa olemasta.
          Säilyttäminen voi myös tapahtua, jos Euroopan tai kansalliset lainsäätäjät ovat säätäneet siitä asetuksissa, laeissa tai muissa
          EU-lainsäädännön alaisissa säännöksissä, joita rekisterinpitäjä on velvoitettu noudattamaan. Tiedot poistetaan myös, jos
          säädetty säilytysaika on päättynyt, ellei tietojen jatkosäilytys ole tarpeen sopimuksen solmimiseksi tai täyttämiseksi.
        </p>
        <p>Käsiteltyjen tietojen tyypit:</p>
        <p>– Yhteystiedot (esim. sähköposti, nimi).</p>
        <p>– Käyttötiedot (esim. vieraillut sivut, kiinnostus sisältöön, käyttöajat).</p>
        <p>– Meta/viestintätiedot (esim. laitteen tiedot, IP-osoitteet).</p>
        <H as="h2">Rekisteröityjen ryhmät</H>
        <p>Verkkotarjouksen vierailijat ja käyttäjät, jotka kaikki tiivistetään jatkossa "käyttäjiksi".</p>
        <H as="h2">Käsittelyn tarkoitus</H>
        <p>– Verkkotarjouksen, sen toimintojen ja sisällön tarjoaminen.</p>
        <p>– Yhteydenottopyyntöihin vastaaminen ja viestintä käyttäjien kanssa.</p>
        <p>– Turvatoimenpiteet.</p>
        <p>– Yleisön mittaus/markkinointi</p>
        <H as="h3">Käytetyt määritelmät</H>
        <p>
          'Henkilötiedot' tarkoittaa kaikkia tietoja, jotka liittyvät tunnistettuun tai tunnistettavissa olevaan luonnolliseen henkilöön ('rekisteröity'); tunnistettavissa oleva luonnollinen henkilö on henkilö, joka voidaan tunnistaa suoraan tai välillisesti erityisesti tunnisteen, kuten nimen, tunnistenumeron, sijaintitietojen, verkkotunnisteen (esim. eväste) tai yhden tai useamman fyysiseen, fysiologiseen, geneettiseen, henkiseen, taloudelliseen, kulttuuriseen tai sosiaaliseen identiteettiin liittyvän tekijän perusteella.
        </p>
        <p>
          'Käsittely' tarkoittaa mitä tahansa toimenpidettä tai toimenpidekokonaisuutta, joka suoritetaan henkilötiedoilla tai henkilötietojen joukolla, riippumatta siitä, suoritetaan se automaattisesti vai ei. Termi on laaja ja kattaa käytännössä kaiken tietojen käsittelyn.
        </p>
        <p>
          'Rekisterinpitäjä' tarkoittaa luonnollista tai oikeushenkilöä, julkista viranomaista, toimielintä tai muuta elintä, joka yksin tai yhdessä toisten kanssa määrittää henkilötietojen käsittelyn tarkoitukset ja keinot.
        </p>
        <H as="h2">Merkitykselliset oikeusperustat</H>
        <p>
          EU:n yleisen tietosuoja-asetuksen (GDPR) 13 artiklan mukaisesti ilmoitan sinulle tietojenkäsittelymme oikeusperustat. Seuraavaa sovelletaan, jos oikeusperustaa ei mainita tietosuojakäytännössä: Oikeusperusta suostumuksen saamiselle on GDPR:n 6 artiklan 1 kohdan a alakohta ja GDPR:n 7 artikla, oikeusperusta palveluidemme suorittamiselle ja sopimuksellisten toimenpiteiden toteuttamiselle sekä yhteydenottoihin vastaamiselle on GDPR:n 6 artiklan 1 kohdan b alakohta, oikeusperusta oikeudellisten velvoitteidemme noudattamiselle on GDPR:n 6 artiklan 1 kohdan c alakohta ja oikeusperusta oikeutettujen etujemme suojaamiselle on GDPR:n 6 artiklan 1 kohdan f alakohta.
        </p>
        <H as="h2">Yhteistyö käsittelijöiden ja kolmansien osapuolten kanssa</H>
        <p>
          Kun käsittelyssä paljastan tietoja muille osapuolille ja yrityksille (käsittelijöille tai kolmansille osapuolille) tai annan suoran tai muun pääsyn tietoihin, tämä tapahtuu vain lakisääteisen luvan perusteella (esim. jos tietojen siirto kolmansille osapuolille, kuten maksupalveluntarjoajalle, on tarpeen sopimuksen täyttämiseksi GDPR:n 6 artiklan 1 kohdan b alakohdan mukaisesti), olet antanut suostumuksesi, oikeudellinen velvoite on olemassa tai oikeutettujen etujemme perusteella (esim. agenttien, verkkopalvelujen tarjoajien käyttö).
        </p>
        <p>
          Kun valtuutan kolmansia osapuolia tietojen käsittelyyn ns. 'käsittelysopimuksen' perusteella, tämä tapahtuu GDPR:n 28 artiklan mukaisesti.
        </p>
        <H as="h2">Tietojen siirto kolmansiin maihin</H>
        <p>
          Kun käsittelen tietoja kolmannessa maassa (eli Euroopan unionin (EU) tai Euroopan talousalueen (ETA) ulkopuolella) tai tämä tapahtuu kolmannen osapuolen palvelujen käytön yhteydessä tai tietojen paljastamisen tai siirron yhteydessä kolmansille osapuolille, tämä tapahtuu vain, jos se on tarpeen sopimuksellisten (esittely)velvoitteidemme täyttämiseksi, suostumuksesi perusteella, oikeudellisen velvoitteen vuoksi tai oikeutettujen etujemme perusteella. Lakisääteisten tai sopimuksellisten lupien alaisena käsittelen tietoja kolmannessa maassa vain, jos GDPR:n 44 artiklan ja sitä seuraavien artiklojen erityisvaatimukset täyttyvät. Toisin sanoen, käsittely tapahtuu esimerkiksi erityisten takausten perusteella, kuten virallisesti tunnustetun EU:n tietosuojatasoa vastaavan tietosuojatason perusteella (esim. Yhdysvalloissa 'Privacy Shield') tai virallisesti tunnustettujen erityisten velvoitteiden noudattamisen perusteella (ns. 'vakiolausekkeet').
        </p>
        <H as="h2">Rekisteröityjen oikeudet</H>
        <p>
          Sinulla on oikeus vaatia vahvistus siitä, käsitelläänkö kyseisiä tietoja, ja saada tietoja näistä tiedoista sekä lisätietoja ja kopio tiedoista GDPR:n 15 artiklan mukaisesti.
        </p>
        <p>
          GDPR:n 16 artiklan mukaisesti sinulla on oikeus vaatia itseäsi koskevien tietojen täydentämistä tai virheellisten tietojen oikaisemista.
        </p>
        <p>
          GDPR:n 17 artiklan mukaisesti sinulla on oikeus vaatia itseäsi koskevien tietojen poistamista ilman aiheetonta viivytystä tai vaihtoehtoisesti GDPR:n 18 artiklan mukaisesti vaatia tietojen käsittelyn rajoittamista.
        </p>
        <p>
          Sinulla on oikeus saada itsesi antamat tiedot GDPR:n 20 artiklan mukaisesti ja vaatia niiden siirtämistä toiselle rekisterinpitäjälle.
        </p>
        <p>
          Sinulla on myös oikeus GDPR:n 77 artiklan mukaisesti tehdä valitus asianomaiselle valvontaviranomaiselle.
        </p>
        <H as="h2">Peruutusoikeus</H>
        <p>Sinulla on oikeus peruuttaa annettu suostumus GDPR:n 7 artiklan (3) kohdan mukaisesti tulevaisuudessa.</p>
        <H as="h2">Vastustamisoikeus</H>
        <p>
          GDPR:n 21 artiklan mukaisesti voit vastustaa sinua koskevien tietojen käsittelyä tulevaisuudessa.
          Erityisesti vastustus voi kohdistua suoramarkkinointiin.
        </p>
        <H as="h2">Evästeet ja oikeus vastustaa suoramarkkinointia</H>
        <p>
          Evästeet ovat pieniä tiedostoja, jotka tallennetaan käyttäjien tietokoneille. Evästeisiin voidaan tallentaa erilaisia tietoja.
          Evästeen ensisijaisena tarkoituksena on tallentaa käyttäjän (tai, kuten voi olla, laitteen, jolle eväste on tallennettu) tiedot käyttäjän käynnin aikana tai sen jälkeen verkkotarjouksen puitteissa. Evästeitä, jotka poistetaan käyttäjän poistuessa verkkotarjouksesta ja sulkiessa selaimensa, kutsutaan tilapäisiksi evästeiksi tai istuntoevästeiksi. Esimerkiksi kirjautumistila voidaan tallentaa tällaiseen evästeeseen. Evästeitä, jotka pysyvät selaimen sulkemisen jälkeen, kutsutaan pysyviksi evästeiksi. Esimerkiksi tämä mahdollistaa kirjautumistilan säilyttämisen, jos käyttäjä vierailee sivustolla useiden päivien jälkeen. Käyttäjien kiinnostukset yleisön mittaus- tai markkinointitarkoituksia varten voidaan myös tallentaa tällaiseen evästeeseen. Evästeitä, jotka tarjotaan muiden kuin verkkotarjouksen rekisterinpitäjän toimesta, kutsutaan kolmannen osapuolen evästeiksi (muuten omia evästeitä, jos vain omat evästeet ovat käytössä).
        </p>
        <p>Voin käyttää tilapäisiä ja pysyviä evästeitä, ja selvitän tämän tietosuojakäytännössä.</p>
        <p>
          Jos käyttäjät eivät halua evästeiden tallentuvan tietokoneelleen, heidän tulee poistaa tämä vaihtoehto järjestelmäasetuksista. Tallennetut evästeet voidaan poistaa selaimen järjestelmäasetuksista.
          Evästeiden poissulkeminen voi johtaa tämän verkkotarjouksen rajoitettuun toimivuuteen.
        </p>
        <p>
          Yleisen vastalauseen evästeiden käytölle online-markkinointitarkoituksiin voi esittää useiden palveluiden kautta, erityisesti seurantaan liittyen, Yhdysvaltojen sivuston http://www.aboutads.info/choices/ tai EU:n sivuston http://www.youronlinechoices.com/ kautta. Lisäksi evästeiden tallentamisen voi estää poistamalla ne selaimen asetuksista. Huomaa, että kaikkia tämän verkkotarjouksen toimintoja ei välttämättä voida käyttää.
        </p>
        <p>
          Kun käyttäjät vierailevat verkkosivustollamme, heille ilmoitetaan evästeiden käytöstä analyysitarkoituksiin info-bannerilla, joka viittaa tietosuojakäytäntöön. Tässä yhteydessä viitataan myös siihen, kuinka evästeiden tallentamisen voi estää selaimen asetuksista. &nbsp;Tämä ns. evästeiden seurantasuodatin auttaa käyttäjää tallentamaan, mitkä evästeet tulee asettaa. Hänen oma evästeensä tallennetaan tätä tarkoitusta varten. Jos poistat evästeesi, älä poista tätä evästettä, sillä muuten evästeiden seurantasuodatin ei pysty tunnistamaan asetuksiasi.
        </p>
        <H as="h2">Tietojen poistaminen</H>
        <p>
          Minun käsittelemäni tiedot poistetaan GDPR:n 17 ja 18 artiklojen mukaisesti tai niiden käsittelyä rajoitetaan.
          Ellei tietosuojakäytännössä toisin mainita, säilytetyt tiedot poistetaan heti, kun niitä ei enää tarvita tarkoitukseensa, eikä lakisääteiset säilytysvelvoitteet estä poistamista. Jos tietoja ei poisteta, koska ne ovat tarpeen muiden lakisääteisten tarkoitusten vuoksi, niiden käsittelyä rajoitetaan. Toisin sanoen tiedot estetään ja niitä ei käsitellä muihin tarkoituksiin. Esimerkiksi tämä koskee tietoja, jotka on säilytettävä kaupallisten tai verotuksellisten syiden vuoksi.
        </p>
        <H as="h2">Liiketoimintaan liittyvä käsittely</H>
        <p>Käsittelen myös</p>
        <p>– sopimustietoja (esim. sopimuskohde, kesto, asiakaskategoria).</p>
        <p>– maksutietoja (esim. pankkitiedot, maksuhistoria)</p>
        <p>
          asiakkaideni, potentiaalisten asiakkaideni ja liikekumppaneideni osalta sopimuspalveluiden tarjoamiseksi,
          huollon ja asiakaspalvelun, markkinoinnin, mainonnan ja markkinatutkimuksen tarkoituksiin.
        </p>
        <H as="h2">Isännöinti</H>
        <p>
          Käyttämäni isännöintipalvelut tarjoavat seuraavat palvelut: Infrastruktuuri- ja alustan palvelut, laskentakapasiteetti, tallennustila ja tietokantapalvelut sekä turvallisuuspalvelut ja tekniset ylläpitopalvelut, joita käytän tämän verkkotarjouksen toiminnan ylläpitämiseksi.
        </p>
        <p>
          Näin tehdessään minä tai, kuten voi olla, isännöintipalveluntarjoajani käsittelemme inventaaritietoja, yhteystietoja, sisältötietoja, sopimustietoja, käyttötietoja, meta- ja viestintätietoja asiakkaiden ja potentiaalisten asiakkaiden sekä tämän verkkotarjouksen vierailijoiden osalta oikeutettujen etujemme perusteella tarjota tehokkaasti ja turvallisesti tämä verkkotarjous GDPR:n 6 artiklan (1, f) kohdan mukaisesti yhdessä GDPR:n 28 artiklan kanssa (käsittelysopimuksen tekeminen).
        </p>
        <H as="h2">Pääsytietojen ja lokitiedostojen kerääminen</H>
        <p>
          Oikeutettujen etujeni perusteella GDPR:n 6 artiklan (1, f) kohdan mukaisesti minä tai, kuten voi olla, isännöintipalveluntarjoajani keräämme tietoja kaikista pääsyistä palvelimelle, jolla tämä palvelu sijaitsee (ns. palvelimen lokitiedostot). Pääsytiedot sisältävät käytetyn verkkosivuston nimen, tiedoston, käyttöpäivämäärän ja -ajan, siirretyn datan määrän, onnistuneen käytön ilmoituksen, selaimen tyypin ja version, käyttäjän käyttöjärjestelmän, viittaavan URL-osoitteen (aiemmin vierailtu sivusto), IP-osoitteen ja pyynnön tehneen palveluntarjoajan.
        </p>
        <p>
          IP-osoitteen tilapäinen tallentaminen järjestelmään on tarpeen verkkosivuston toimittamiseksi käyttäjän tietokoneelle. Käyttäjän IP-osoite on säilytettävä istunnon ajan tämän mahdollistamiseksi.
        </p>
        <p>
          Tallennus tapahtuu lokitiedostoissa verkkosivuston toimivuuden varmistamiseksi, erityisesti hyökkäyksen (DOS-hyökkäys) ja tarjottujen työkalujen väärinkäytön analysoimiseksi. Tiedot palvelevat myös verkkosivuston optimointia ja IT-järjestelmieni turvallisuuden varmistamista. Tietoja ei arvioida markkinointitarkoituksiin tässä yhteydessä.
        </p>
        <p>
          Tiedot poistetaan heti, kun niiden keräystarkoitus ei ole enää tarpeen. Tämä tapahtuu yleensä 30 päivän jälkeen.
        </p>
        <p>
          Tietojen kerääminen verkkosivuston tarjoamiseksi ja tietojen tallentaminen lokitiedostoihin on välttämätöntä verkkosivuston toiminnan kannalta. Tästä syystä käyttäjä ei voi vastustaa tätä.
        </p>
        <H as="h2">Sopimuspalveluiden tarjoaminen</H>
        <p>
          Käsittelen asiakastietoja (esim. nimet ja osoitteet sekä yhteystiedot) ja sopimustietoja (esim. käytetyt palvelut, yhteyshenkilöiden nimet, maksutiedot) sopimusvelvoitteideni ja palveluideni täyttämiseksi GDPR:n 6 artiklan (1, b) kohdan mukaisesti. Online-lomakkeissa pakollisiksi merkityt kentät ovat tarpeen sopimuksen solmimiseksi.
        </p>
        <p>
          Käyttäessäni online-palveluitani tallennan IP-osoitteen ja kunkin käyttäjätoiminnon ajan. Tallennus tapahtuu oikeutettujen etujeni perusteella, myös käyttäjän suojaamiseksi väärinkäytöltä ja luvattomalta käytöltä. Näitä tietoja ei pääsääntöisesti luovuteta kolmansille osapuolille, ellei se ole tarpeen vaatimusteni seuraamiseksi tai jos siihen on lakisääteinen velvoite GDPR:n 6 artiklan (1, c) kohdan mukaisesti.
        </p>
        <p>
          Käsittelen käyttötietoja (esim. verkkotarjoukseni sivut, kiinnostus tuotteisiini) ja sisältötietoja (esim. yhteydenottolomakkeen tai käyttäjäprofiilin merkinnät) mainostarkoituksiin käyttäjäprofiilissa näyttääkseni käyttäjille esimerkiksi tuoteinformaatioita aiemmin käyttämiesi palveluiden perusteella.
        </p>
        <p>
          Tiedot poistetaan lakisääteisten takuuaikojen ja vastaavien velvoitteiden umpeuduttua, ja tietojen säilyttämisen tarve tarkistetaan kolmen vuoden välein. Lakisääteisten arkistointivelvoitteiden osalta tiedot poistetaan niiden päättyessä. Asiakastilin tiedot säilyvät, kunnes se poistetaan.
        </p>
        <H as="h2">Hallinto, kirjanpito, toimistojen järjestely, yhteystietojen hallinta</H>
        <p>
          Käsittelen tietoja hallinnollisten tehtävien, toimintani järjestämisen, kirjanpidon ja lakisääteisten velvoitteiden, kuten arkistoinnin, osana. Näin käsittelen samoja tietoja, joita käsittelen tarjotessani sopimuspalveluitani. Käsittelyn perusteet ovat GDPR:n 6 artiklan (1, c) ja GDPR:n 6 artiklan (1, f) kohdat. Käsittely koskee asiakkaita, potentiaalisia asiakkaita, liikekumppaneita ja verkkosivuston vierailijoita. Käsittelyn tarkoitus ja kiinnostus liittyy hallintoon, kirjanpitoon, toimistojen järjestelyyn ja tietojen arkistointiin, eli tehtäviin, jotka palvelevat liiketoimintani ylläpitoa, tehtävieni suorittamista ja palvelujeni tarjoamista. Tietojen poistaminen sopimuspalveluiden ja viestinnän osalta vastaa näiden käsittelytoimien yhteydessä annettuja tietoja.
        </p>
        <p>
          Näin ollen paljastan tai siirrän tietoja Verotoimistolle, neuvonantajille, kuten veroneuvojille tai tilintarkastajille, ja muille veloittajille ja maksupalveluntarjoajille.
        </p>
        <p>
          Lisäksi kaupallisten etujeni perusteella säilytän tietoja toimittajista, järjestäjistä ja muista liikekumppaneista, esimerkiksi myöhempää yhteydenottoa varten. Pääsääntöisesti jaan näitä tietoja yrityksiin liittyen pysyvästi.
        </p>
        <H as="h2">Liiketoiminta-analyysi ja markkinatutkimus</H>
        <p>
          Jotta voin toimia kaupallisesti ja tunnistaa markkinatrendit sekä asiakas- ja käyttäjätoiveet, analysoin käytettävissäni olevia tietoja liiketoimintaprosesseista, sopimuksista, kyselyistä jne. Näin käsittelen inventaaritietoja, viestintätietoja, sopimustietoja, maksutietoja, käyttötietoja ja metatietoja GDPR:n 6 artiklan (1, f) kohdan mukaisesti, kun rekisteröityjä ovat asiakkaat, potentiaaliset asiakkaat, liikekumppanit sekä verkkotarjouksen vierailijat ja käyttäjät.
        </p>
        <p>
          Analyysi tapahtuu liiketoiminnan arvioinnin, markkinoinnin ja markkinatutkimuksen tarkoituksiin. Tämä mahdollistaa rekisteröityjen käyttäjien profiilien huomioimisen esimerkiksi heidän ostotapahtumiensa perusteella. Analyysi auttaa minua parantamaan käyttäjäystävällisyyttä ja optimoimaan tarjoustani ja liiketoimintani tehokkuutta. Analyysi palvelee vain minua, eikä sitä luovuteta ulkopuolisille, ellei kyseessä ole anonymisoitu analyysi tiivistetyillä arvoilla.
        </p>
        <p>
          Jos analyysi tai profiilit ovat henkilökohtaisia, ne poistetaan tai anonymisoidaan käyttäjän lopettamisen yhteydessä, muuten kahden vuoden kuluttua sopimuksen päättymisestä. Lisäksi mahdollisuuksien mukaan tuotetaan yleisiä kaupallisia analyysejä ja yleistä trendien määritystä.
        </p>
        <H as="h2">Sähköpostirekisteröitymistoiminto</H>
        <p>
          Käyttäjillä on mahdollisuus tilata sisältöni sähköpostitse. Käyttäjille annetaan tarvittavat pakolliset tiedot rekisteröityessään. Rekisteröityessä annettuja tietoja käytetään tarjouksen hyödyntämiseen. Käyttäjät voivat saada sähköposteja tarjouksiin tai tarjouksen laajuuden tai teknisten ehtojen muutoksiin liittyen. Jos käyttäjät ovat peruuttaneet käyttäjätilinsä, heidän tietonsa käyttäjätilin osalta poistetaan, ellei säilyttämistä vaadita kaupallisista tai verotuksellisista syistä GDPR:n 6 artiklan (1, c) kohdan mukaisesti. Käyttäjien vastuulla on suojata tietonsa ennen sopimuksen päättymistä peruutuksen tapauksessa. Olen oikeutettu peruuttamattomasti poistamaan kaikki käyttäjän sopimuksen aikana tallennetut tiedot.
        </p>
        <p>
          Kun käytän rekisteröitymis- ja kirjautumistoimintojani sekä käyttäjätilin käyttöä, tallennan IP-osoitteen ja kunkin käyttäjätoiminnon ajan. Tallennus tapahtuu paitsi oikeutettujen etujeni perusteella myös käyttäjän suojaamiseksi väärinkäytöltä ja muulta luvattomalta käytöltä. Näitä tietoja ei pääsääntöisesti luovuteta kolmansille osapuolille, ellei se ole tarpeen vaatimusteni seuraamiseksi tai jos siihen on lakisääteinen velvoite GDPR:n 6 artiklan (1, c) kohdan mukaisesti.
        </p>
        <H as="h2">Yhteydenotto</H>
        <p>
          Ottaessasi yhteyttä minuun (esimerkiksi yhteydenottolomakkeen, sähköpostin, puhelimen tai sosiaalisen median kautta), käyttäjän tiedot käsitellään yhteydenottopyynnön käsittelemiseksi ja GDPR:n 6 artiklan (1, b) kohdan mukaisesti. Käyttäjän tiedot voidaan tallentaa asiakassuhteen hallintajärjestelmään (CRM-järjestelmä) tai vastaavaan kyselyjen hallintajärjestelmään.
        </p>
        <p>
          Poistan kyselyt heti, kun niitä ei enää tarvita. Tarkistan tarpeen kahden vuoden välein, ja lakisääteiset arkistointivelvoitteet pätevät myös.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default PrivacyPolicy;










  